<template>
  <div>
    <div>
      <img src="@/assets/img/stateBar2.png" alt="" class="stateBar" />
    </div>
    <header>
      <p class="header_p">
        <span class="header_back">&lt;</span>
        <span class="header_name">师资团队</span>
      </p>
    </header>
    <nav class="nav">
      <ul class="nav_list">
        <li>
          <span>级别</span>
          <img src="@/assets/img/xiala.png" alt="" class="xiala" />
        </li>
        <li>
          <span>部门</span>
          <img src="@/assets/img/xiala.png" alt="" class="xiala" />
        </li>
      </ul>
    </nav>
    <div class="main">
      <ul class="card_list">
        <li
          v-for="(teacher, index) in teacherInfos"
          :key="teacher"
          class="card"
          @click="goTeacherClassPage(teacher.name)"
        >
          <img :src="teacher.img" alt="" class="teacher_img" />
          <div class="teacherInfo">
            <img :src="this.prizeImgs[index]" alt="" class="prize" />
            <p class="info_wrap">
              <span class="name">{{ teacher.name }}</span>
              <span class="level">{{ teacher.level }}</span>
              <span class="department">{{ teacher.department }}</span>
            </p>
            <p class="intro">{{ teacher.intro }}</p>
            <p class="addInfo">
              <span class="addNum">{{ teacher.resourceNum }}</span>
              <span class="addDes">资源</span>
              <span class="addNum">{{ teacher.browserNum }}</span>
              <span class="addDes">浏览</span>
              <span class="addNum">{{ teacher.score }}</span>
              <span class="addDes">分</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teacherInfos: [
        {
          img: require("@/assets/img/teacher2.png"),
          name: "王勇",
          level: "中级讲师",
          department: "安环部",
          resourceNum: 12,
          browserNum: 3480,
          score: 5.0,
          intro:
            "讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍",
        },
        {
          img: require("@/assets/img/teacher2.png"),
          name: "吕毅鹏",
          level: "中级讲师",
          department: "滚装物流",
          resourceNum: 12,
          browserNum: 3480,
          score: 4.8,
          intro:
            "讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍",
        },
        {
          img: require("@/assets/img/teacher2.png"),
          name: "刘璐璐",
          level: "中级讲师",
          department: "客运公司",
          resourceNum: 12,
          browserNum: 3480,
          score: 4.8,
          intro:
            "讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍",
        },
        {
          img: require("@/assets/img/teacher2.png"),
          name: "王钰兰",
          level: "中级讲师",
          department: "审计部",
          resourceNum: 12,
          browserNum: 3480,
          score: 4.8,
          intro:
            "讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍讲师介绍",
        },
      ],
      prizeImgs: [
        require("@/assets/img/no1.png"),
        require("@/assets/img/no2.png"),
        require("@/assets/img/no3.png"),
        require("@/assets/img/no4.png"),
      ],
    };
  },
  methods: {
    goTeacherClassPage(name) {
      this.$router.push("/TeacherClass/" + name);
    },
  },
};
</script>

<style lang="less" scoped>
.stateBar {
  display: block;
  width: 750px;
  height: 75px;
}
.header_p {
  text-align: center;
  font-size: 36px;
}
.header_back {
  position: absolute;
  left: 20px;
  font-weight: bold;
}
.nav {
  width: 100%;
}
.nav_list {
  display: flex;
  width: 100%;
  border-bottom: 5px solid #f6f6f6;
  padding: 30px 0;
  font-size: 28px;
}
.nav_list li {
  flex: 1;
  text-align: center;
}
.xiala {
  width: 20px;
  height: 20px;
  margin-left: 25px;
}
.card_list {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 40px;
}
.card {
  width: 685px;
  height: 310px;
  box-shadow: 0 0 5px rgb(214, 212, 212);
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  position: relative;
}
.teacher_img {
  width: 210px;
  height: 300px;
}
.teacherInfo {
  flex: 1;
}
.prize {
  position: absolute;
  top: -55px;
  width: 150px;
  height: 170px;
  right: -20px;
}
.info_wrap {
  margin-top: 45px;
  margin-left: 18px;
  margin-bottom: 30px;
}
.name {
  font-size: 32px;
  font-weight: bold;
}
.level {
  font-size: 24px;
  color: #fe893a;
  margin-left: 60px;
}
.department {
  font-size: 24px;
  color: #999999;
  position: absolute;
  right: 25px;
}
.intro {
  font-size: 24px;
  color: #999999;
  padding: 0 30px;
  text-indent: 48px;
}
.addInfo {
  margin-top: 30px;
  font-size: 22px;
  margin-left: 40px;
}
.addNum {
  margin-right: 5px;
}
.addDes {
  color: #999999;
  margin-right: 40px;
}
</style>