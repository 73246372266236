<template>
  <div class="manage">
    <div>
      <img src="@/assets/img/stateBar.png" alt="" class="stateBar" />
    </div>
    <div>
      <img src="@/assets/img/banner2.png" alt="" class="banner" />
    </div>
    <div class="card_wrap">
      <ul class="card_list">
        <li
          v-for="(card, index) in card_list"
          :key="card.kindName"
          class="card"
          :class="card.border_shadow"
          @click="goTrainClass(card.kindName)"
        >
          <div class="rank_wrap" v-if="index < 3 && index >= 0">
            <img src="@/assets/img/crown.png" alt="" class="crown" />
            <span>NO.</span>
            <span>{{ index + 1 }}</span>
          </div>
          <div class="card_left" :class="card.bg_color">
            <span class="card_alias">{{ card.kindAlias }}</span>
          </div>
          <div class="card_right">
            <h1 class="card_kindName">{{ card.kindName }}</h1>
            <ul class="card_addInfo_list">
              <li class="card_addItem">
                <span class="card_num">{{ card.resourceNum }}</span>
                <span class="card_num_info">资源</span>
              </li>
              <li class="card_addItem">
                <span class="card_num">{{ card.browserNum }}</span>
                <span class="card_num_info">浏览</span>
              </li>
              <li class="card_addItem">
                <span class="card_num">{{ card.subscribeNum }}</span>
                <span class="card_num_info">订阅</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { courseCategorySum } from "@/network/netService";
export default {
  data() {
    return {
      card_list: [
        {
          kindAlias: "党",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 1,
          bg_color: {
            red: true,
            yellow: false,
            orange: false,
            blue: false,
          },
          border_shadow: {
            border_red: true,
            border_yellow: false,
            border_orange: false,
            border_blue: false,
          },
        },
        {
          kindAlias: "纪",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 2,
          bg_color: {
            red: false,
            yellow: true,
            orange: false,
            blue: false,
          },
          border_shadow: {
            border_red: false,
            border_yellow: true,
            border_orange: false,
            border_blue: false,
          },
        },
        {
          kindAlias: "党建",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 3,
          bg_color: {
            red: false,
            yellow: false,
            orange: false,
            blue: true,
          },
          border_shadow: {
            border_red: false,
            border_yellow: false,
            border_orange: false,
            border_blue: true,
          },
        },
        {
          kindAlias: "人",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 4,
          bg_color: {
            red: false,
            yellow: false,
            orange: true,
            blue: false,
          },
          border_shadow: {
            border_red: false,
            border_yellow: false,
            border_orange: true,
            border_blue: false,
          },
        },
        {
          kindAlias: "政",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 5,
          bg_color: {
            red: false,
            yellow: false,
            orange: true,
            blue: false,
          },
          border_shadow: {
            border_red: false,
            border_yellow: false,
            border_orange: true,
            border_blue: false,
          },
        },
        {
          kindAlias: "企",
          kindName: "",
          resourceNum: "",
          browserNum: "",
          subscribeNum: "54",
          rank: 6,
          bg_color: {
            red: false,
            yellow: false,
            orange: true,
            blue: false,
          },
          border_shadow: {
            border_red: false,
            border_yellow: false,
            border_orange: true,
            border_blue: false,
          },
        },
      ],
    };
  },
  methods: {

    goTrainClass(kindName) {
      // this.$router.push({path: '/trainClass', query: kindName});
      this.$router.push({path:"/trainClass",query:{kindName:kindName}})
    },
  },
  created() {
    courseCategorySum("param", (res) => {
        this.card_list[0].kindName = res.listPartyBuilding.courseCategory;
        this.card_list[0].resourceNum = res.listPartyBuilding.count;
        this.card_list[0].browserNum = res.listPartyBuilding.bowseNumber;

      this.card_list[1].kindName = res.listInspection.courseCategory;
      this.card_list[1].resourceNum = res.listInspection.count;
      this.card_list[1].browserNum = res.listInspection.bowseNumber;

      this.card_list[2].kindName = res.listSecurity.courseCategory;
      this.card_list[2].resourceNum = res.listSecurity.count;
      this.card_list[2].browserNum = res.listSecurity.bowseNumber;

      this.card_list[3].kindName = res.listHumanResources.courseCategory;
      this.card_list[3].resourceNum = res.listHumanResources.count;
      this.card_list[3].browserNum = res.listHumanResources.bowseNumber;

      this.card_list[4].kindName = res.listAdministration.courseCategory;
      this.card_list[4].resourceNum = res.listAdministration.count;
      this.card_list[4].browserNum = res.listAdministration.bowseNumber;

      this.card_list[5].kindName = res.listEnterprise.courseCategory;
      this.card_list[5].resourceNum = res.listEnterprise.count;
      this.card_list[5].browserNum = res.listEnterprise.bowseNumber;

    });
  },
};
</script>

<style lang="less" scoped>
.stateBar {
  display: block;
  width: 750px;
  height: 55px;
}
.banner {
  display: block;
  width: 750px;
  height: 240px;
}
.card_list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.card {
  position: relative;
  display: flex;
  flex: 1;
  gap: 45px;
  padding: 37px 40px;
}
.card:nth-child(1),
.card:nth-child(2),
.card:nth-child(3) {
  margin-top: 40px;
}
.card_addInfo_list {
  display: flex;
  flex-direction: row;
}
.card_wrap {
  padding: 50px 35px;
}
.card_left {
  width: 85px;
  height: 85px;
  border-radius: 100px;
  text-align: center;
}
.card_alias {
  line-height: 85px;
  color: white;
  font-size: 26px;
}
.card_kindName {
  font-size: 28px;
}
.card_addInfo_list {
  font-size: 24px;
  margin-top: 22px;
}
.card_addInfo_list li {
  margin-left: 35px;
}
.card_addInfo_list li:first-of-type {
  margin-left: 0;
}
.card_num {
  margin-right: 8px;
}
.card_num_info {
  color: #a0a0a0;
}
.red {
  background-color: #fe635e;
  border: 10px solid #ffb9b7;
}
.yellow {
  background-color: #ffa200;
  border: 10px solid #ffe2c8;
}
.blue {
  background-color: #5ecbfe;
  border: 10px solid #d5f2ff;
}
.orange {
  background-color: #fe923a;
  border: 10px solid #ffd1ac;
}
.border_red {
  border: 1px solid #ffb9b7;
  box-shadow: 0 0 10px #ffb9b7;
}
.border_yellow {
  border: 1px solid #ffe2c8;
  box-shadow: 0 0 10px #ffe2c8;
}
.border_blue {
  border: 1px solid #d5f2ff;
  box-shadow: 0 0 10px #d5f2ff;
}
.border_orange {
  border: 1px solid #ffd1ac;
  box-shadow: 0 0 10px #ffd1ac;
}
// 排名区
.rank_wrap {
  position: absolute;
  top: -40px;
  left: 0;
  width: 140px;
  height: 40px;
  background-color: #fe635e;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  text-align: center;
  color: white;
  font-size: 26px;
  line-height: 40px;
}
.card:nth-child(1) .rank_wrap {
  background-color: #fe635e;
}
.card:nth-child(2) .rank_wrap {
  background-color: #ffa200;
}
.card:nth-child(3) .rank_wrap {
  background-color: #5ecbfe;
}
.crown {
  margin-right: 8px;
  transform: translateY(15%);
  width: 40px;
}
</style>
