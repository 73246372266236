<template>
  <div>
    <div>
      <img src="@/assets/img/stateBar2.png" alt="" class="stateBar" />
    </div>
    <header>
      <p class="header_p">
        <span class="header_back">&lt;</span>
        <span class="header_name">{{ name }}</span>
      </p>
    </header>
    <div class="main">
      <ul class="classList">
        <li v-for="classCard in classList" :key="classCard" class="classCard">
          <img :src="classCard.img" alt="" class="card_img" />
          <div class="card_right">
            <h2 class="card_theme">{{ classCard.theme }}</h2>
            <p class="card_add">
              <span class="card_score">{{ classCard.score }}分</span>
              <span> / </span>
              <span>{{ classCard.browserNum }}万人浏览</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      classList: [
        {
          theme: "打造企业新中层 (4集)",
          img: require("@/assets/img/img1.png"),
          score: 4.7,
          browserNum: 3.5,
        },
        {
          theme: "组织领导力精品课: 组织转型破茧成蝶 (7讲)",
          img: require("@/assets/img/img2.png"),
          score: 4.7,
          browserNum: 4.7,
        },
        {
          theme: "突破性领导力 (4集)",
          img: require("@/assets/img/img3.png"),
          score: 4.7,
          browserNum: 4,
        },
        {
          theme: "儒释道领导模式",
          img: require("@/assets/img/img4.png"),
          score: 4.7,
          browserNum: 3.5,
        },
        {
          theme: "领导力十项修炼 (上)",
          img: require("@/assets/img/img5.png"),
          score: 4.7,
          browserNum: 3.5,
        },
        {
          theme: "领导力十项修炼 (下)",
          img: require("@/assets/img/img5.png"),
          score: 4.7,
          browserNum: 3.5,
        },
        {
          theme: "组织行为与领导力 (4集)",
          img: require("@/assets/img/img6.png"),
          score: 4.7,
          browserNum: 3.5,
        },
      ],
    };
  },
  created() {
    this.name = this.$route.params.name;
  },
};
</script>

<style lang="less" scoped>
.stateBar {
  display: block;
  width: 750px;
  height: 75px;
}
.header_p {
  text-align: center;
  font-size: 36px;
}
.header_back {
  position: absolute;
  left: 20px;
  font-weight: bold;
}
.navItem_xiala {
  width: 15px;
  height: 15px;
}
.classList{
    display: flex;
    flex-direction: column;
    padding: 70px 10px;
}
.classCard{
    display: flex;
    padding: 30px;
    height: 160px;
    border-bottom: 1px rgb(199, 198, 198) solid;
}
.card_right{
    margin-left: 25px;
}
.card_img{
    width: 200px;
}
.card_theme{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}
.card_add{
    font-size: 26px;
    color: #999999;
}
</style>