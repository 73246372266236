const request = require("./netUtils");

// // 获取周末大讲堂数据
module.exports.weekendList = function (kind, callback) {
  const config = {
    url: "/api/app/zhiniao/weekendList",
    method: "post",
    // data: {  },
  };
  request(config)
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};



//获取积分排行数据
module.exports.integralList = function (happenTime,yearweek,orgName,integralType,callback) {
    const config = {
        url: "/api/app/zhiniao/integralList?happenTime="+happenTime+"&yearweek="+yearweek+"&orgName="+orgName+"&integralType="+integralType,
        method: "post",
        // data: integral
    };
    request(config)
        .then((res) => {
            callback(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

//获取积分排行数据
module.exports.lecturerIntegralList = function (happenTime,yearweek,orgName,integralType,callback) {
    const config = {
        url: "/api/app/zhiniao/lecturerIntegralList?happenTime="+happenTime+"&yearweek="+yearweek+"&orgName="+orgName+"&integralType="+integralType,
        method: "post",
        // data: integral
    };
    request(config)
        .then((res) => {
            callback(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};


//获取管理类点击进入的详细数据
module.exports.partyList = function (kind,kindName,callback) {
  const config = {
    url: "/api/app/zhiniao/partyList?kindName="+kindName,
    method: "post",
    // data: {kindName:kindName}
  };
  request(config)
      .then((res) => {
        callback(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
};

//获取管理类数据
module.exports.courseCategorySum = function (kind, callback) {
    const config = {
        url: "/api/app/zhiniao/courseCategorySum",
        method: "post",
        // data: {  },
    };
    request(config)
        .then((res) => {
            callback(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};


