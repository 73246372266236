<template>
  <div class="content">
    <!-- Icon 合集 -->
    <svg
      style="height: 0; width: 0; display: none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <symbol id="icon_back" width="1rem" height="1rem" viewBox="0 0 16 16">
        <path
          fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </symbol>
      <symbol id="icon_share" width="1rem" height="1rem" viewBox="0 0 16 16">
        <path
          d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
        />
      </symbol>
      <symbol id="icon_play" width="2rem" height="2rem" viewBox="0 0 16 16">
        <path
          d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
        />
      </symbol>
      <symbol id="icon_eye" width="1rem" height="1rem" viewBox="0 0 16 16">
        <path
          d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
        />
        <path
          d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
        />
      </symbol>
      <symbol id="icon_chat" width="1.2rem" height="1.2rem" viewBox="0 0 16 16">
        <path
          d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        />
        <path
          d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"
        />
      </symbol>
      <symbol id="icon_star" width="1.2rem" height="1.2rem" viewBox="0 0 16 16">
        <path
          d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
        />
      </symbol>
      <symbol
        id="icon_download"
        width="1.2rem"
        height="1.2rem"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
        />
      </symbol>
      <symbol id="icon_note" width="1.2rem" height="1.2rem" viewBox="0 0 16 16">
        <path
          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
        />
        <path
          fill-rule="evenodd"
          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
        />
      </symbol>
      <symbol id="icon_pencil" width="1rem" height="1rem" viewBox="0 0 16 16">
        <path
          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
        />
      </symbol>
    </svg>

    <header class="header">
      <svg class="icon_back"><use xlink:href="#icon_back" /></svg>
      <svg class="icon_share"><use xlink:href="#icon_share" /></svg>
    </header>
    <main>
      <div class="play_content">
        <img :src="playCover" class="playCover" alt="" />
        <svg class="icon_play">
          <use class="icon_play_use" xlink:href="#icon_play" />
        </svg>
      </div>
      <div class="des_content">
        <nav class="nav">
          <ul class="nav_list">
            <li @click="toggleNav(1)">
              <span :class="{ navItem_active: currentNavNum == 1 }">介绍</span>
            </li>
            <li @click="toggleNav(2)">
              <span :class="{ navItem_active: currentNavNum == 2 }">目录</span>
            </li>
          </ul>
        </nav>
        <div v-if="currentNavNum == 1">
          <div class="word_des">
            <p class="theme">{{ theme }}</p>
            <p>
              <svg class="icon_eye">
                <use class="icon_eye_use" xlink:href="#icon_eye" />
              </svg>
              <span class="watchedPersonNum">{{ watchedPersonNum }}人看过</span>
            </p>
          </div>
          <div class="detail_content">
            <h2 class="tuwen">图文详情</h2>
            <p>{{ detailContent }}</p>
          </div>
        </div>
        <div v-else-if="currentNavNum == 2">
          <p class="process_row">
            <span class="ucomple_span">未完成</span>
            <span class="process_span">进度0/1</span>
            <span class="rule_span"
              >完成规则
              <span class="rule_cycle">?</span>
            </span>
          </p>
          <ul class="class_list">
            <li class="class_item" v-for="item in classList" :key="item.name">
              <p>
                <span class="mp4_span">MP4</span>
                <span class="className_span">{{ item.name }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </main>
    <footer class="footer">
      <svg class="icon_pencil">
        <use class="icon_pencil_use" xlink:href="#icon_pencil" />
      </svg>
      <input
        type="text"
        class="note_input"
        placeholder="评论未开放"
        disabled
        style="text-indent: 28px"
      />
      <ul class="footer_list">
        <li>
          <svg class="icon_chat footer_icon">
            <use class="icon_chat_use" xlink:href="#icon_chat" />
          </svg>
          <p class="icon_dex">评论</p>
        </li>
        <li>
          <svg class="icon_star footer_icon">
            <use class="icon_star_use" xlink:href="#icon_star" />
          </svg>
          <p class="icon_dex">收藏</p>
        </li>
        <li>
          <svg class="icon_download footer_icon">
            <use class="icon_download_use" xlink:href="#icon_download" />
          </svg>
          <p class="icon_dex">下载</p>
        </li>
        <li>
          <svg class="icon_note footer_icon">
            <use class="icon_note_use" xlink:href="#icon_note" />
          </svg>
          <p class="icon_dex">记笔记</p>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //记录当前导航页码
      currentNavNum: 1,
      playCover: require("@/assets/img/1-6.png"),
      theme: "十九届六中全会坚定不移全面从严治党",
      detailContent: "十九届六中全会坚定不移全面从严治党",
      watchedPersonNum: 123,
      classList: [
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
        {
          name: "十九届六中全会1--坚定不移全面从严治党",
        },
      ],
    };
  },
  methods: {
    toggleNav(index) {
      this.currentNavNum = index;
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  background-color: white;
  height: 2rem;
  z-index: 999;
}
.icon_back {
  position: absolute;
  left: 10px;
  width: 54px;
  height: 54px;
  top: 20px;
}
.icon_share {
  position: absolute;
  right: 10px;
  width: 54px;
  height: 54px;
  top: 20px;
}
.playCover {
  width: 70%;
  display: block;
  margin: 0 auto;
  clear: both;
}
.play_content {
  position: relative;
  padding: 2rem 50px;
}
.icon_play {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 4px solid white;
  border-radius: 100px;
}
.icon_play_use {
  transform: translate(22px, 17px);
  margin: 0 auto;
  fill: white;
  width: 50px;
  height: 50px;
}
.des_content {
  border-top: solid 20px rgb(239, 236, 236);
}
.nav_list {
  display: flex;
}
.nav_list > li {
  flex: 1;
  text-align: center;
  padding: 20px 0;
}
.nav_list > li > span {
  padding-bottom: 15px;
}
.navItem_active {
  border-bottom: 5px solid rgb(254, 137, 57);
  color: rgb(254, 137, 57);
}
.icon_eye {
  width: 1rem;
  height: 1rem;
}
.icon_eye_use {
  fill: rgb(148, 147, 147);
}
.watchedPersonNum {
  color: rgb(148, 147, 147);
  font-size: 26px;
  margin-left: 10px;
}
.word_des {
  padding: 50px;
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: solid 17px rgb(245, 245, 245);
}
.theme {
  margin-bottom: 30px;
  font-size: 1.1rem;
}
.icon_eye_use {
  transform: translateY(5px);
}
.detail_content {
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.tuwen {
  font-size: 1rem;
  margin-bottom: 40px;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  border-top: solid 1px gray;
  display: flex;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: white;
}
.icon_pencil {
  position: absolute;
  width: 30px;
  top: 35px;
  left: 35px;
  fill: gray;
}
.note_input {
  border-radius: 100px;
  background-color: rgb(233, 231, 231);
  height: 70px;
  border: none;
  flex: 1;
}
.footer_list {
  flex: 1;
  display: flex;
}
.footer_list li {
  flex: 1;
  text-align: center;
}
.footer_icon {
  width: 40px;
  height: 40px;
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
}
.icon_dex {
  font-size: 26px;
  color: rgb(127, 125, 125);
}
.process_row {
  padding: 10px;
  margin-top: 10px;
}
.ucomple_span {
  color: #ffc96f;
  margin-right: 20px;
}
.process_span {
  color: #a6a6a6;
}
.rule_span {
  float: right;
  margin-right: 20px;
  color: #777777;
}
.rule_cycle {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 2px solid #777777;
  border-radius: 100px;
  text-align: center;
  font-size: 0.8rem;
}
.class_item {
  padding: 60px 20px;
}
.mp4_span {
  margin-right: 32px;
  border: 2px solid #a6a6a6;
  border-radius: 15px;
  color: #a6a6a6;
  font-size: 00.8rem;
  padding: 4px 8px;
}
.className_span {
  color: #ee8f4d;
}
.class_list {
  display: flex;
  flex-direction: column;
  padding-bottom: 400px;
}
</style>
