<template>
  <router-view></router-view>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
</style>

<script></script>
