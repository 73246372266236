import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'
import WeekClass from "../views/WeekClass.vue";
import ManagementClass from "../views/ManagementClass";
import GradeRank from "../views/GradeRank";
import TeacherClass from "../views/TeacherClass";
import TeacherTeam from "../views/TeacherTeam";
import TrainClass from "../views/TrainClass";
import ClassDetail from "../views/ClassDetail";
import LecturerIntegral from "../views/LecturerIntegral";


const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: "/weekClass",
    name: "weekClass",
    component: WeekClass,
  },
  {
    path: "/managementClass",
    name: "managementClass",
    component: ManagementClass,
  },
  {
    path: "/gradeRank",
    name: "GradeRank",
    component: GradeRank,
    meta: {
      title: "部门积分排行榜"
    },
    beforeEnter: (to, from) => {
      // reject the navigation
      document.title=to.meta.title
    },
  },
  {
    path: "/teacherClass/:name",
    name: "teacherClass",
    component: TeacherClass,
  },
  {
    path: "/teacherTeam",
    name: "teacherTeam",
    component: TeacherTeam,
  },
  {
    path: "/trainClass",
    name: "trainClass",
    component: TrainClass,
  },
  {
    path: "/classDetail",
    name: "classDetail",
    component: ClassDetail,
  },
  {
    path: "/lecturerIntegral",
    name: "lecturerIntegral",
    component: LecturerIntegral,
    meta: {
      title: "师资积分排行榜"
    },
    beforeEnter: (to, from) => {
      // reject the navigation
      console.log("??")
      document.title=to.meta.title
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
