// 设置基准大小, 与postcss中根字体的配置相等
const baseSize = 32;

function setRem() {
  //设计图大小750px 
  const scale = document.documentElement.clientWidth / 750;
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px";
}
setRem();
window.onresize = function () {
  setRem();
};
