<template>
  <div class="gradeRank_wrap">
    <img src="@/assets/img/beijing.png" alt="" class="bg_img"/>
    <header class="header">
      <nav class="nav">
        <ul class="navList">
          <li
              v-for="(navItem, indexf) in navList"
              :key="navItem"
              class="navItem"
              @click="expand(navItem)"
          >
            <span class="navItem_name">{{ navItem.navItemName }}</span>
            <img src="@/assets/img/xiala.png" alt="" class="navItem_xiala"/>
            <ul class="dropPanel" v-if="navItem.listNum == activeListNum">
              <li
                  v-for="(item, index) in navItem.list"
                  :key="item"
                  @click="toggleRankPage(index, item, navItem, indexf)"
              >
                {{ item }}
              </li>
            </ul>
          </li>
        </ul>
        <div class="search_btn">
          <img src="@/assets/img/search.png" alt="" class="search_btn_img"/>
        </div>
      </nav>
    </header>

    <div class="rank_content">
      <img src="@/assets/img/paihang.png" alt="" class="rank_theme_img"/>
      <div>
        <img src="@/assets/img/rank_prizebg.png" alt="" class="rank_prizebg"/>
        <!--   前三名     -->
        <ul class="threeUserList">
          <li v-for="user in topThreeList" :key="user">
            <img
                src="@/assets/img/touxiang.png"
                class="threeUserList_touxiang"
            />
            <div class="threeitemInfo_wrap">
              <p class="threeitem_username">{{ user.empName }}</p>
              <p class="threeitem_usercompany">{{ user.company }}</p>
              <p class="threeitem_userdepartment">{{ user.orgName }}</p>
              <p class="threeitem_userrank">{{ user.score }}</p>
            </div>
          </li>
        </ul>
        <div class="otherRank">
          <img src="@/assets/img/paiming_bg.png" alt="" class="otherRank_bg"/>
          <div class="oRank_userList_wrap">
            <ul class="oRank_userList">
              <li v-for="user in userInfoList" :key="user" class="oRank_user">
                <div class="rankFlag">
                  <img src="@/assets/img/rankflag.png" alt=""/>
                  <span>{{ user.rankL }}</span>
                </div>
                <div class="userInfoCenter">
                  <div class="oR_touxiang">
                    <img v-bind:src="user.photo" alt="">
                  </div>
                  <div class="oR_userInfo">
                    <h2 class="oR_name">{{ user.empName }}</h2>
                    <p class="oR_com">
                      {{ user.company }}-{{ user.orgName }}
                    </p>
                  </div>
                </div>
                <div class="oR_userRank">
                  <p class="oR_rank">{{ user.score }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 滚动列表弹窗 -->
    <div class="bscroll" ref="bscroll">
      <div class="bscroll-container"></div>
    </div>
  </div>
</template>

<script>
import {integralList} from "@/network/netService";

export default {
  data() {
    return {
      navList: [
        {navItemName: "总积分", listNum: 1, list: ["总积分", "学习积分"]},
        {
          navItemName: "总榜",
          listNum: 2,
          list: ["总榜", "周榜", "月榜", "季榜", "年榜"],
        },
        {navItemName: "2022", listNum: 3, list: ["2022", "2021"]},
        {
          navItemName: "全部部门",
          listNum: 4,
          list: ["全部部门", "干部上讲堂", "人力资源部"],
        },
      ],
      activeListNum: 0,
      //前三名
      topThreeList: [
      ],
      //排名列表数据
      userInfoList: [],
      integral: {
        happenTime: 2022,
        yearweek: "",
        orgName: "",
        integralType: "",
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      integralList(
          this.integral.happenTime,
          this.integral.yearweek,
          this.integral.orgName,
          this.integral.integralType,
          (res) => {
            this.topThreeList.push({},{},{});
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].photo.lastIndexOf('default2') > 0) {
                res.data[i].photo = 'http://mlearn.pingan.com.cn/learn/app/default1';
              }
              res.data[i].company = "山东港口烟台港";
              res.data[i].rankL = i + 1;
              if (i == 0) {
                this.topThreeList[1] = res.data[i];
              } else if (i == 1) {
                this.topThreeList[0] = res.data[i];
              } else if (i == 2) {
                this.topThreeList[2] = res.data[i];
              } else {
                this.userInfoList.push(res.data[i]);
              }

            }
          }
      );
    },
    // 是否图片404
    imageLoad(imgurl) {
      return new Promise(function (resolve, reject) {
        var ImgObj = new Image() // 判断图片是否存在
        ImgObj.src = imgurl
        ImgObj.onload = function (res) {
          resolve(res)
        }
        ImgObj.onerror = function (err) {
          reject(err)
        }
      })
    },
    async printFn(img) {
      let imgarr = []
      let noImgIndex = [];
      for (let i = 0; i < img.length; i++) {
        await this.imageLoad(img[i].img).then(() => {
          imgarr.push(img[i].img)
        }).catch(() => {
          noImgIndex.push(i)
        })
      }
      return noImgIndex
    },
    expand(item) {
      if (item.listNum == this.activeListNum) {
        this.activeListNum = 0;
      } else {
        this.activeListNum = item.listNum;
      }
    },
    toggleRankPage(index, item, navItem, indexf) {
      navItem.navItemName = item;
      if (indexf == 0) {
        this.integral.integralType = item;
      } else if (indexf == 1) {
        this.integral.yearweek = index - 1;
      } else if (indexf == 2) {
        this.integral.happenTime = item;
      } else if (indexf == 3) {
        if (item == "全部部门") {
          this.integral.orgName = "";
        } else {
          this.integral.orgName = item;
        }
      }
      this.topThreeList = [];
      this.userInfoList = [];
      this.list();
    },
  },
};
</script>

<style lang="less" scoped>
.bg_img {
  position: absolute;
  top: -110px;
  width: 750px;
  height: 1800px;
  z-index: -1;
}

.stateBar {
  display: block;
  width: 750px;
  height: 75px;
}

.header {
  position: fixed;
  width: 100%;
  clear: both;
  background-color: white;
  z-index: 999;
}

.header_p {
  text-align: center;
  font-size: 36px;
}

.header_back {
  position: absolute;
  left: 20px;
  font-weight: bold;
}

.nav {
  position: relative;
  padding: 30px 40px 15px 40px;
}

.navList {
  width: 90%;
  display: flex;
  gap: 15px;
}

.navItem {
  flex: 2;
  display: inline-block;
}

.navItem:nth-child(1) {
  flex: 4.5;
}

.navItem:nth-child(2) {
  flex: 3;
}

.navItem:nth-child(3) {
  flex: 3;
}

.navItem:nth-child(4) {
  flex: 6;
}

.navItem_name {
  margin-right: 5px;
}

.navItem_xiala {
  width: 15px;
  height: 15px;
}

.search_btn {
  position: absolute;
  right: 25px;
  top: 25px;
}

.search_btn_img {
  width: 45px;
  height: 42px;
}

.rank_theme_img {
  display: block;
  margin: 0 auto;
  width: 645px;
  margin-top: 20px;
}

.rank_content {
  position: absolute;
  clear: both;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.rank_prizebg {
  position: absolute;
  width: 750px;
  z-index: -1;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
}

.threeUserList {
  display: flex;
}

.threeUserList li {
  flex: 1;
}

.threeUserList_touxiang {
  display: block;
  width: 120px;
  border-radius: 120px;
}

.threeUserList li:nth-child(1) .threeUserList_touxiang {
  margin-left: 32px;
  margin-top: 8px;
}

.threeUserList li:nth-child(2) .threeUserList_touxiang {
  margin-left: 35px;
  margin-top: -21px;
  width: 145px;
}

.threeUserList li:nth-child(3) .threeUserList_touxiang {
  margin-left: 62px;
  margin-top: 8px;
}

.threeitem_username {
  font-size: 28px;
}

.threeitem_usercompany {
  font-size: 22px;
  color: #808080;
}

.threeitem_userdepartment {
  font-size: 22px;
  color: #808080;
}

.threeUserList li:nth-child(1) .threeitem_userrank {
  font-size: 22px;
  width: 170px;
  height: 30px;
  background-color: #ececec;
  color: #868686;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  margin-left: 20px;
}

.threeUserList li:nth-child(2) .threeitem_userrank {
  font-size: 22px;
  width: 170px;
  height: 30px;
  background-color: #ffefc9;
  color: #ffaf00;
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
}

.threeUserList li:nth-child(3) .threeitem_userrank {
  font-size: 22px;
  width: 170px;
  height: 30px;
  background-color: #d1bc9f;
  color: #885918;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  margin-left: 25px;
}

.threeUserList li:nth-child(1) .threeitemInfo_wrap {
  text-align: center;
  margin-top: 50px;
  padding-left: 0px;
}

.threeUserList li:nth-child(2) .threeitemInfo_wrap {
  text-align: center;
  margin-top: 50px;
}

.threeUserList li:nth-child(3) .threeitemInfo_wrap {
  text-align: center;
  margin-top: 50px;
  padding-right: 0px;
}

.otherRank {
  position: relative;
  top: 50px;
  overflow: hidden;
  height: 980px;
}

.otherRank_bg {
  width: 730px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  height: 100%;
}

.oRank_userList_wrap {
  overflow: scroll;
  height: 850px;
  margin-top: 20px;
}

.oRank_userList {
  display: flex;
  flex-direction: column;
  width: 650px;
  margin: 0 auto;
  margin-top: 50px;
  gap: 25px;
  height: 1000px;
}

.oRank_user {
  display: block;
  position: relative;
  width: 610px;
  min-height: 140px;
  margin: 0 auto;
  border: 1px #f0ad7b solid;
  transform: translateX(-5px);
  border-radius: 15px;
}

.rankFlag {
  position: absolute;
  top: 0px;
  left: 25px;
  display: inline-block;
}

.rankFlag img {
  width: 35px;
  height: 40px;
  display: block;
}

.rankFlag span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #df8141;
}

.oRank_user {
}

.oR_touxiang {
  display: inline-block;
  width: 80px;
}

.oR_touxiang img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
}

.oR_userInfo {
  display: inline-block;
  margin-left: 20px;
}

.oR_userRank {
  display: inline-block;
}

.oR_name {
  font-size: 34px;
}

.oR_com {
  font-size: 22px;
  color: #868686;
}

.userInfoCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
}

.oR_userRank {
  right: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}

.oR_rank {
}

.personal_rank {
  position: relative;
  top: 100px;
  width: 100%;
  background-color: white;
  height: 140px;
}

.personal_rank .oR_com {
  font-size: 28px;
}

.pR_rank {
  font-size: 28px;
}

.pR_rankNum {
  font-weight: bold;
}

.pR_rankL {
  font-size: 28px;
}

.pR_rankLNum {
  color: #e29d5f;
  font-weight: bold;
}

.personalInfo {
  left: 34px;
}

.personalImg_wrap .personalImg {
  width: 90px;
  transform: translateY(5px);
}

.dropPanel {
  position: absolute;
  background: white;
  width: 200px;
}

.dropPanel li {
  padding: 10px;
}

.bscroll {
  width: 100%;
  height: 5vh;
  overflow: hidden;
}
</style>
