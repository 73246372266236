<template>
  <div class="weekClass">
    <div>
      <img src="@/assets/img/stateBar.png" alt="" class="stateBar" />
    </div>
    <div>
      <img src="@/assets/img/banner1.png" alt="" class="banner" />
    </div>
    <header class="header">
      <div class="header_pre">
        <img src="@/assets/img/zhuanti.png" alt="" class="zhuanti" />
      </div>
      <div class="header_content">
        <h1 class="header_theme">周末大讲堂</h1>
        <ul class="header_staticsList">
          <li
            v-for="item in staticsList"
            :key="item.type"
            class="header_staticsItem"
          >
            <span class="header_staticsItem_num">{{ item.num }}</span>
            <span class="header_staticsItem_type">{{ item.type }}</span>
          </li>
        </ul>
        <button class="header_subscribe" @click="subScribe()">订阅</button>
      </div>
    </header>
    <div class="gap"></div>
    <div class="content">
      <nav class="content_nav">
        <ul class="content_navList">
          <li
            v-for="navItem in navList"
            :key="navItem.name"
            class="content_navitem"
            :class="{ orange: navItem.isClicked }"
            @click="toggleFunction(navItem)"
          >
            {{ navItem.name }}
          </li>
        </ul>
      </nav>
      <div class="content_main">
        <ul class="card_list">
          <li class="card" v-for="card in cardList" :key="card.src">
            <div class="card_left">
              <img :src="card.src" alt="" class="card_img" />
            </div>
            <div class="card_right">
              <p>{{ card.header }}</p>
              <p class="card_addition">
                <span class="department">{{ card.department }}</span>
                <span class="browserNum">{{ card.browserNum }}</span>
                <span class="browser">浏览</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { weekendList } from "@/network/netService";
export default {
  data() {
    return {
      staticsList: [
        { type: "资源", num: 12 },
        { type: "浏览", num: 3480 },
        { type: "订阅", num: 54 },
      ],
      navList: [
        {
          name: "全部",
          isClicked: true,
        },
        {
          name: "生产业务部",
          isClicked: false,
        },
        {
          name: "人力资源部",
          isClicked: false,
        },
        {
          name: "企业管理部",
          isClicked: false,
        },
      ],
      cardList: [
      ],
    };
  },
  created() {
    weekendList("param", (res) => {
      for (var i=0;i<res.data.length;i++){
        this.cardList.push({src:null,header:null,department:null,browserNum:null});
        this.cardList[i].src = res.data[i].image;
        this.cardList[i].header = res.data[i].courseName;
        this.cardList[i].department = res.data[i].courseCategory;
        this.cardList[i].browserNum = res.data[i].bowseNumber;

      }
    });
  },
  methods: {
    toggleFunction(navItem) {
      this.navList.forEach((element) => {
        element.isClicked = false;
      });
      navItem.isClicked = true;
    },

    subScribe() {

    },
  },
};
</script>

<style  lang="less" scoped>
.stateBar {
  display: block;
  width: 750px;
  height: 55px;
}
.banner {
  display: block;
  width: 750px;
  height: 282px;
}
.header {
  width: 100%;
  height: 273px;
  overflow: hidden;
  position: relative;
}
.header_pre {
  margin-top: 42px;
  margin-left: 25px;
}
.zhuanti {
  width: 115px;
  height: 50px;
}
.header_content {
  height: 170px;
}
.header_theme {
  width: 100%;
  font-size: 44px;
  font-weight: 200;
  padding-left: 38px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.header_staticsList {
  margin-left: 38px;
}
.header_staticsItem {
  display: inline-block;
  margin-right: 34px;
}
.header_staticsItem_num {
  font-size: 26px;
  margin-right: 8px;
}
.header_staticsItem_type {
  font-size: 26px;
  color: rgb(150, 150, 150);
}
.header_subscribe {
  position: absolute;
  right: 40px;
  top: 175px;
  width: 177px;
  height: 70px;
  font-size: 32px;
  color: white;
  background-color: rgb(254, 137, 57);
  border: none;
  border-radius: 50px;
}
// 间隙
.gap {
  width: 100%;
  height: 28px;
  background-color: #f6f6f6;
}
// 主体导航
.content_navList {
  width: 100%;
  padding: 36px 32px;
}
.content_navitem {
  display: inline-block;
  margin-right: 50px;
  font-size: 32px;
  padding-bottom: 5px;
  cursor: pointer;
}
.orange {
  border-bottom: 5px solid #fe8939;
  color: #fe8939;
}
.content_navitem:hover {
  .orange();
}
.content_main {
  width: 100%;
}
.card_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.card {
  display: flex;
  gap: 20px;
  flex-direction: row;
  padding: 0 50px;
  font-size: 32px;
}
.card_left {
  flex: 1;
}

.card_right {
  flex: 2;
}
.card_img {
  width: 220px;
  height: 150px;
}
.department {
  font-size: 20px;
  border: 1px solid #fe8939;
  background-color: #feefe4;
  color: #fe8939;
  padding: 3px;
  margin-right: 20px;
}
.browserNum {
  font-size: 22px;
}
.browser {
  font-size: 22px;
}
.card_addition {
  margin-top: 8px;
}
</style>
