<template>
  <div>
    <div>
      <img src="@/assets/img/stateBar2.png" alt="" class="stateBar" />
    </div>
    <header>
      <p class="header_p">
        <span class="header_back">&lt;</span>
        <span class="header_name">{{this.$route.query.kindName}}</span>
      </p>
    </header>
    <nav class="nav">
      <ul class="nav_list">
        <li>
          <span>最新</span>
          <img src="@/assets/img/xiala.png" alt="" class="xiala" />
        </li>
        <li>
          <span>课程类型</span>
          <img src="@/assets/img/xiala.png" alt="" class="xiala" />
        </li>
      </ul>
    </nav>
    <div class="main">
      <ul class="classList">
        <li v-for="classCard in classList" :key="classCard" class="classCard">
          <img :src="classCard.img" alt="" class="card_img" />
          <div class="card_right">
            <h2 class="card_theme">{{ classCard.theme }}</h2>
            <p class="card_add">
              <span class="card_score">{{ classCard.score }}</span>
              <span> / </span>
              <span>{{ classCard.browserNum }}人浏览</span>
              <span class="author">{{ classCard.author }}</span>
            </p>
            <p class="read_wrap">
              <span v-if="classCard.isRead" class="read">{{
                classCard.isRead
              }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { partyList } from "@/network/netService";
export default {

  data() {
    return {
      classList: [
      ],
    };
  },
  created() {
    var kindName =this.$route.query.kindName;
    console.log(kindName)
    partyList("param",kindName,(res) => {
      for (var i=0;i<res.data.length;i++){
        this.classList.push({});
        this.classList[i].img = res.data[i].image;
        this.classList[i].theme = res.data[i].courseName;
        this.classList[i].score = res.data[i].totalRating;
        this.classList[i].browserNum = res.data[i].bowseNumber;

      }
    });
  },
};

</script>

<style lang="less" scoped>
.stateBar {
  display: block;
  width: 750px;
  height: 75px;
}
.header_p {
  text-align: center;
  font-size: 36px;
}
.header_back {
  position: absolute;
  left: 20px;
  font-weight: bold;
}
.nav {
  width: 100%;
}
.nav_list {
  display: flex;
  width: 100%;
  border-bottom: 25px solid #f6f6f6;
  padding: 30px 0;
  font-size: 28px;
}
.nav_list li {
  flex: 1;
  text-align: center;
}
.xiala {
  width: 20px;
  height: 20px;
  margin-left: 25px;
}
.classList {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
}
.classCard {
  display: flex;
  padding: 70px 30px;
  height: 160px;
  border-bottom: 1px rgb(199, 198, 198) solid;
}
.card_right {
  margin-left: 25px;
}
.card_img {
  width: 210px;
}
.card_theme {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}
.card_add {
  font-size: 26px;
  color: #999999;
}
.author {
  color: black;
  position: absolute;
  right: 40px;
}
.read_wrap {
  font-size: 28px;
  color: #40a585;
}
.read {
  position: absolute;
  right: 40px;
}
</style>
